/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import AlertHeader from "../AlertHeader/AlertHeader";
import { AlertCentreCardTitle, AlertCentrediv, AlertCentreKey, AlertCentreMessage, AlertCentreValue, AlertTableRow } from "./AlertCentre.styles";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { AppRoutes, HeaderType, UserRole } from "../../../../constants/enums";
import { Row, Col, Form,Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActiveAlert,
  fetchAlertByOrgId,
  fetchTransformedAlertCount,
} from "../../../../../redux/actions/alert/alertAction";
import { AlertSelector, setAdditionalApiPayload, clearUpdateActiveAlert } from "../../../../../redux/reducers/alert/alertReducer";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { clearState } from "../../../../../redux/reducers/alert/alertReducer";
import { cameFromOrganisation, getOrganizationDateAndTime, getHeight, getElapsedTime, urlModification } from "../../../../../utility/appUtil";
import { appSelector,setAlertClicked } from "../../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { AlertStatusDropdown, ClearAlertMethod, ALERT_DEFAULT_LIMIT, ALERT_PAGE_SIZE, DEFAULT_PAGE, Pages, PickerFormat,OrganisationDatePicker,jsonData, DevicePickerFormat } from "../../../../constants/constants";
import Loader from "../../../stateless/common/spinner";
import './AlertCentre.less'
import { AntdInfinityTable } from "../../../stateless/common/antdTable/antdInfininityTable";
import { deviceDeleteIcon, ErrorIcon, expandArrow, warningIcon } from "../../../../images";
import { AlertCentreTableHeader } from "./AlertCentreDummyData";
import { getStatusTypeEllipse, onlyUnique, prepareFilterList, sortList } from "../../../../../utility/utils";
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction";
import { fetchDeviceListByOrgId, fetchPartNameList } from "../../../../../redux/actions/device/deviceAction";
import { localeInfo } from "../../../../models/user/localization";
import AntdPagination from "../../../stateless/common/pagination/pagination";
import { AlertClearForm } from "./AlertClearForm";
import { AppService } from "../../../../services/ui/appService";
import { Messages } from "../../../../constants/messages";
import { eventLogListSelector } from "../../../../../redux/reducers/eventLogs/eventLogListReducer";
import { fetchEventsInfo } from "../../../../../redux/actions/eventLogs/eventListAction";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import { DeviceSelector, clearPartNameList } from "../../../../../redux/reducers/device/deviceReducer";
import { useTranslation } from 'react-i18next';
import CustomFilter from "../../../stateless/common/customTableFilter";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import { Button } from "../../../../../bit_components/common/button";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";


interface alertElements {
  severity: string,
  hierarchyName: string,
  srcName: string,
  eventTimeStamp: string,
  updatedAt: string,
  alertTypeId: number,
  isCleared: boolean,
  alertName: string,
  message: string,
  clearingMethod:string,
  updatedBy:string,
  reason:string
  DisplayText: string
  partName: string
}
interface ClearAlert {
  alertId: string
  eventSrc: string
  userId: string
  reason: string
  organizationId: string
  category: string
  partId?: number
  partInstanceId?: number
  id?:number
}

interface FilteredValues {
  severityText: null | string[]
  alertName: null | string[]
  hierarchyName: null | string[]
  srcName: null | string[]
  partName: null | string[]

}

interface FilteredValues {
  severityText: null | string[]
  alertName: null | string[]
  hierarchyName: null | string[]
  srcName: null | string[]


}

const AlertCentre = (props: any) => {
  const dispatch = useDispatch()
  const location: any = useLocation()
  const { eventsInfo } = useSelector(eventLogListSelector)
  const { locations } = useSelector(locationListSelector)
  const { deviceList, partNameList } = useSelector(DeviceSelector)
  const [scrollHeight, setScrollHeight] = useState(200)
  const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
  const [screenWidth, setScreenWidth] = useState(document.body.clientWidth)
  const [unfilteredData, setUnfilteredData] = useState([])
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  const [rowId, setRowId] = useState<any>(0);
  const [form] = Form.useForm()
  const [disable, setDisable] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [pageChanged, setPageChanged] = useState<boolean>(false)
  const [apiParams, setApiParams] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const [scrolledToEnd, setScrolledToEnd] = useState(false)
  const [apiCalled, setApiCalled] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [totalCleared, setTotalCleared] = useState(0)
  // const [totalRows, setTotalRows] = useState(0)
  const [severityFilter, setSeverityFilter] = useState([])
  const [alertFilter, setAlertFilter] = useState([])
  const [filteredInfo, setFilteredInfo] = useState<any>(null)
  const [tableColumns, setTableColumns] = useState([])
  const [selectedSeverity, setSelectedSeverity] = useState<any>()
  const [selectedAlert, setSelectedAlert] = useState<any>()
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [alertType, setAlertType] = useState(AlertStatusDropdown.ACTIVE_VALUE)
  const [locationFilter, setLocationFilter] = useState([])
  const [deviceFilter, setDeviceFilter] = useState([])
  const [selectedLocation, setSelectedLocation] = useState<any>()
  const [selectedDevice, setSelectedDevice] = useState<any>()
  const [filtersValue, setFiltersValue] = useState<FilteredValues>();
  const [partNameFilter, setpartNameFilter] = useState([])
  const { t } = useTranslation()
  const history = useHistory()
  const {
    alerts,
    totalAlerts,
    fetchAlertFormState,
    alertCleared,
    alertClearFormState,
    additionalApiPayload
  } = useSelector(AlertSelector);
  const { selectedOrganisation, alertSeverity ,alertClicked} = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const [selectedDeviceStates, setSelectedDeviceStates] = useState<any>({});
  const [reset, setReset] = useState<boolean>(false);
  // useEffect(() => {
  //   setTotalRows(totalAlerts - totalCleared)
  // }, [totalAlerts, totalCleared])

  useEffect(() => {
    // if(location?.state?.warnNotifications){
    //   setSelectedSeverity([Status_Type.WARNING])
    // }
    // else if(location?.state?.errorNotifications){
    //   setSelectedSeverity([Status_Type.ERROR])
    // }
    if (alertSeverity) {
      setSelectedSeverity([alertSeverity])
    }
  }, [alertSeverity])

  useEffect(() => {

    if(location?.state?.location){
      let info = location?.state?.location
      let filters: any = {hierarchyName:[info?.location]}

      setSelectedLocation(info?.location)
      if(location?.state?.addCategory){
        filters = {...filters,alertName:[info?.alertName]}
        setSelectedAlert([info?.alertName])
      }
      setFiltersValue({...filtersValue,...filters})
      setFilteredInfo({...filteredInfo,...filters})
    }

    else if(location?.state?.device){
      let info = location?.state?.device
      let filters: any = {srcName:[info?.device]}

      setSelectedDevice(info?.device)
      if(location?.state?.addCategory){
        filters = {...filters,alertName:[info?.alertName]}
        setSelectedAlert([info?.alertName])
      }
      setFiltersValue({...filtersValue,...filters})
      setFilteredInfo({...filteredInfo,...filters})
    }

    else if(location?.state?.alert) {      
      let info = location?.state?.alert
      let filters:any
      setSelectedAlert([info?.alertName])
      filters={...filters,alertName:[info?.alertName]}
        if(info?.hierarchyId && !location?.state?.removeHierarchy) {
          filters={...filters,hierarchyName:[info?.hierarchyId] }
          setSelectedLocation(info?.hierarchyId)
        }

        if(info?.src && info?.srcType === "device" && !info?.isArchived && !location?.state?.removeDevice) {
          filters={...filters,srcName:[info?.src] }
          setSelectedDevice(info?.src)
        }
        setFilteredInfo(filters)
        setFiltersValue(filters)        
    }    
    
  }, [location?.state])

  useEffect(() => {
    if (additionalApiPayload) {
      let filters: any;

      setSelectedAlert([additionalApiPayload?.alertName]);
      filters = { ...filters, alertName: [additionalApiPayload?.alertName] };

      filters = { ...filters, srcName: [additionalApiPayload?.srcName] };
      setSelectedDevice(additionalApiPayload?.srcName);

      setFilteredInfo(filters);
      setFiltersValue(filters);
    }
  }, [additionalApiPayload]);
  
  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  useEffect(() => {
    if (appUser &&
      (appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN &&
        appUser.locations[0].role.name !== UserRole.ORGANISATION_ADMIN
      )
    ) {
      setDisable(true)
    }
  }, [appUser])

  useEffect(() => {
    clearState();
    dispatch(fetchPartNameList(getOrgId()))
    dispatch(fetchTransformedLocationList(getOrgId()))
    dispatch(fetchEventsInfo(null))
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(sidebarNavigate(SideBarItems.ALERTS_CENTRE));
    dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }));
    const node = document.body
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenHeight(node?.clientHeight)
      setScreenWidth(node?.clientWidth)
    });
    resizeObserver.observe(node);
    return () => {
      dispatch(clearPartNameList())
    };
  }, []);

  useEffect(() => {
    if (!fetchAlertFormState?.loading && !loading) {

      //table height
      const tableHeight = getHeight('.ant-table-body')

      //to calculate table footer height
      const compContentHeight = getHeight('.ant-layout-content')
      const bottomGap = compContentHeight - (screenHeight * 0.8)
      const pagination_div = document.querySelector('.alert-pagination')?.clientHeight
      const footerHeight = (totalAlerts / ALERT_DEFAULT_LIMIT) > 1 ? pagination_div + bottomGap : 0;

      //to calculate final height of the table
      const calculatedHeight = tableHeight - footerHeight
      const scrollH = parseInt(calculatedHeight.toFixed(0));
      !isNaN(scrollH) && setScrollHeight(scrollH)
    }
  }, [screenHeight, screenWidth, fetchAlertFormState?.loading, loading])

  useEffect(() => {
    if (alerts?.length && apiCalled) {
      setUnfilteredData(alerts)
      setTableData(alerts?.slice(currentIndex, currentIndex + ALERT_PAGE_SIZE))
      setCurrentIndex(currentIndex + ALERT_PAGE_SIZE);
      setLoading(false)
      setApiCalled(false)
    }
    else if (!fetchAlertFormState?.loading && apiCalled) {
      setLoading(false)
      setApiCalled(false)
    }
  }, [alerts])

  useEffect(() => {
    if (fetchAlertFormState?.isError && apiCalled) {
      setLoading(false)
      setApiCalled(false)
    }
  }, [fetchAlertFormState])


  const getLocations = (list: any) => {
    const arrLocation = list?.map((x: any) => {
      return {
        text: x.name,
        value: x.id,
      }
    })
    const sortedBylabel = arrLocation.sort(sortList('text'));
    return sortedBylabel
  }

  const getDeviceList = (list: any) => {
    const arrDevices = list?.map((x: any) => {
      return {
        text: x.info.name ?? (x.serialNo ?? x.id),
        value: x.id,
      }
    })
    const sortedBylabel = arrDevices.sort(sortList('text'));
    return sortedBylabel
  }

  useEffect(() => {
    if (selectedLocation?.length < locations?.length && selectedLocation?.length) {
      dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: selectedLocation }))
    }
    else {
      dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
    }
  }, [selectedLocation])

  useEffect(() => {

    //filter table  by dropdown type
    const typeListItems = eventsInfo?.map((data: any) => data.severity.toString().toLowerCase());
    const typeUniqueLocation = typeListItems?.filter(onlyUnique)?.filter((x: any) => x?.toString()?.toLowerCase() !== 'good');
    const alertList = eventsInfo?.map((data: any) => data.event?.toString());
    const uniqueAlertList = alertList?.filter(onlyUnique)
    setSeverityFilter(prepareFilterList(typeUniqueLocation))
    setAlertFilter(prepareFilterList(uniqueAlertList))
    setLocationFilter(getLocations(locations))
    setDeviceFilter(getDeviceList(deviceList))
  }, [eventsInfo, locations, deviceList])

  useEffect(() => {
    setpartNameFilter(prepareFilterList(partNameList));
  }, [partNameList]);

  const getAlertIcon = (type: string) => {
    switch (type?.toLowerCase()) {
      case 'warning':
        return (
          <img className="alertCentre-icon" src={warningIcon} alt="" />
        )
      case 'error':
        return (
          <img className="alertCentre-icon" src={ErrorIcon} alt="" />
        )
      default:
        return (
          <span className="alertCentre-icon" />
        )
    }
  }
  let localeInfo: any = secureLocalStorage.getItem('locale');
    const dateFormat = (localeInfo.culture == 'en-US')
    ?  OrganisationDatePicker.DATE_TIME_US
    : OrganisationDatePicker.DATE_TIME_GB;

  const alertCentreTableColumn = (severityFilter: any, filteredInfA:any, alertFilter: any, locationFilter: any, deviceFilter: any, partNameFilter: any ) => {

    const selectedDeviceStateUpdate = (data: any, key: any,reset:any) => {
      const updatedDeviceStates = { ...filteredInfo };
      setReset(reset)
      if (updatedDeviceStates?.hasOwnProperty(key)) {
        setFilteredInfo({...filteredInfo,[`${key}`]:data[key]})
      } else {
        setFilteredInfo({...filteredInfo,...data})
      }
      if(key === 'hierarchyName'){
        setSelectedLocation(data[key])
      }
      setFiltersChanged(true)
    };
    return [
      {
        title: <div className="headText">
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Severity)}>
        <p className='alertHeadingText' > {t(jsonData.Severity)}</p>
        </Tooltip>
         <CustomFilter 
            optionList={severityFilter} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={filteredInfo?.severityText??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity22"
            wrapperClassName="state-multiselect"
            coulmnName="severityText"
            filterName={t(jsonData.severityFilter)}
            />
        </div>,
        dataIndex: "severity",
        key: "severityText",
        width: 50,
        ellipsis: true,
        // filters: severityFilter,
        filteredValue: filteredInfo?.severityText || null,
        render(severity) {
          return <span className="warnicons">
            <>
              <span>
                <img src={getStatusTypeEllipse(severity?.toString())} alt="" />
              </span>
              <span>{severity}</span>
            </>
          </span>
        }
      },
      {
        title:  <div className="headText">
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Alert)}>
        <p className='alertHeadingText bold-700'> {t(jsonData.Alert)} </p>
        </Tooltip>
         <CustomFilter 
            optionList={alertFilter} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={filteredInfo?.alertName??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-alert22"
            wrapperClassName="state-multiselect"
            coulmnName="alertName"
            filterName={t(jsonData.alertFilter)}
            allowSearch={true}
            />
        </div>,
        dataIndex: "alertName",
        key: "alertName",
        width: 50,
        ellipsis: true,
        // filters:alertFilter,
        filteredValue: filteredInfo?.alertName || null,
        render(alertName) {
          return <span className="bold-700">{alertName}</span>
        }
      },
      {
        title: <div className="headText">
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Component)}>
        <p className='alertHeadingText' > {t(jsonData.Component)} </p>
        </Tooltip>
           <CustomFilter 
          optionList={partNameFilter} 
          selectedData={selectedDeviceStateUpdate}
          selectedItems={filteredInfo?.partName??[]}
          allSelectedText={t(jsonData.AllStates)}
          placeholder={t(jsonData.SelectState)}
          optionListClass="state-optionList-partNameFilter22"
          wrapperClassName="state-multiselect"
          coulmnName="partName"
          filterName={t(jsonData.partNameFilter)}
          />
        </div>,
        dataIndex: "partName",
        key: "partName",
        width: 50,
        ellipsis: true,
        // filters:partNameFilter,
        filteredValue: filteredInfo?.partName || null,
      },
      {
        title:   <div className="headText"> 
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Location)}>
          <p className='alertHeadingText' > {t(jsonData.Location)} </p>
          </Tooltip>
        <CustomFilter 
          optionList={locationFilter} 
          selectedData={selectedDeviceStateUpdate}
          selectedItems={filteredInfo?.hierarchyName??[]}
          allSelectedText={t(jsonData.AllStates)}
          placeholder={t(jsonData.SelectState)}
          optionListClass="state-optionList-location22"
          wrapperClassName="state-multiselect"
          coulmnName="hierarchyName"
          filterName={t(jsonData.locationFilter)}
          allowSearch={true}
          />
        </div>,
        dataIndex: "hierarchyName",
        key: "hierarchyName",
        width: 50,
        ellipsis: true,
        // filters:locationFilter,
        filteredValue: filteredInfo?.hierarchyName || null,
      },
      {
        title: <div className="headText">
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Device)}>
        <p className='alertHeadingText'> {t(jsonData.Device)} </p>
        </Tooltip>
         <CustomFilter 
            optionList={deviceFilter} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={filteredInfo?.srcName??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-device22"
            wrapperClassName="state-multiselect"
            coulmnName="srcName"
            filterName={t(jsonData.deviceFilter)}
            allowSearch={true}
            />
        </div>,
        // dataIndex: "srcName",
        key: "srcName",
        width: 50,
        ellipsis: true,
        // filters:deviceFilter,
        filteredValue: filteredInfo?.srcName || null,
        render(data) {
          return <span className='deviceRowDivStye'><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={data.srcName}><span className='deviceNamestyle'>{data.srcName}</span></Tooltip>{data?.isArchived  ?  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Deleted"><img src={deviceDeleteIcon} alt="" className='InsightdeviceDeleteIcon'/></Tooltip> : ""}</span>
         
        }
      },
      {
        title: <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={alertType ? t(jsonData.ResolvedAt) : t(jsonData.Occurred)}>
        <div className='headText'>
           <p className="alertHeadingText"> {alertType ? t(jsonData.ResolvedAt) : t(jsonData.Occurred)} </p>
          </div>
          </Tooltip>,
        width: 50,
        ellipsis: true,
        render(row) {
          return <span className="alertCenre-lastColumn">
             {moment(alertType ? row.updatedAt : row.eventTimeStamp).isAfter(moment().subtract(24, 'hours'))
                ? getElapsedTime(alertType ? row.updatedAt : row.eventTimeStamp, { text: 'long' })
                : getOrganizationDateAndTime(alertType ? row.updatedAt : row.eventTimeStamp, dateFormat) 
              }
            <span className="alertCentre-expandIcon">
              <img src={expandArrow} alt="" />
            </span>
          </span>
        }
      },
      
    ]
  }

  const onFinish = (data: any, formData: any) => {
    let clearAlert: ClearAlert = {
      alertId: data?.alertTypeId,
      eventSrc: data?.src,
      userId: appUser?.id,
      reason: formData?.reason,
      organizationId: getOrgId(),
      category: data?.category,
      id:data?.id
    }
    if (clearAlert.category === 'partStatuses') {
      clearAlert.partId = data?.partId
      clearAlert.partInstanceId = data?.partInstanceId
    }
    dispatch(updateActiveAlert({ data: clearAlert, id: data?.id }))
  }

  useEffect(() => {
    if (alertCleared && alertClearFormState?.isSuccess) {

      if (alertCleared?.id) {
        AppService.showToast(t(Messages.ALERT_CLEARED))
        setLoading(true)
        setApiCalled(true)
        setToInitial()
        dispatch(fetchAlertByOrgId(apiParams))
        dispatch(fetchTransformedAlertCount({ organizationId: getOrgId() }))
      }
      else if (alertCleared?.message) {
        AppService.showToast(alertCleared?.message)
      }
      dispatch(clearUpdateActiveAlert())
    }
    else if (alertClearFormState?.isError) {
      AppService.showToast(t(Messages.ERROR_OCCURRED), true)
      dispatch(clearUpdateActiveAlert())
    }
  }, [alertCleared, alertClearFormState])

  //expand table
  //get antd table row id
  const onRow = (record: any) => {
    return {
      onKeyPress:(event: any) => {
        if (rowId !== record.id) {
          setSelectedRowKeys(record.id);
          setRowId(record.id);
          UseGAEventTracker("Alert Center Category", "Alert Center Selected Row", `${record.id} Id Row Selected`);
        }
        else {
          setSelectedRowKeys(0);
          setRowId(0);
        }
        if (!alertType) form.resetFields()
      },
      onClick: (event: any) => {
        if (rowId !== record.id) {
          setSelectedRowKeys(record.id);
          setRowId(record.id);
          UseGAEventTracker("Alert Center Category", "Alert Center Selected Row", `${record.id} Id Row Selected`);
        }
        else {
          setSelectedRowKeys(0);
          setRowId(0);
        }
        if (!alertType) form.resetFields()
      },
    };
  };

  const goToEventLog = (record: any) => {
    const {
      eventTimeStamp,
      clearingEventTimestamp,
      alertTypeId,
      clearingId,
      partName,
      src,
    } = record;

    const locationState = {
      eventOfAlertStatus: true,
      activeAlertTimestamp: eventTimeStamp,
      clearingEventTimestamp: clearingEventTimestamp,      
      alertTypeId: alertTypeId,
      clearingId : clearingId, 
      partName: partName,
      deviceId: src,
    };
    
    history.push(urlModification(AppRoutes.EVENT_LOGS), locationState);
  };


  const expandable = {
    expandedRowRender: (record: alertElements) =>
      <div className="alertCentre-description">
        <div>
          <AlertCentreCardTitle>
            {getAlertIcon(record?.severity)}
            <p>{record?.alertName}</p>
            <Button type="actionButton export-report ml-15" onClick={()=> goToEventLog(record)}>
              {t(jsonData.ViewEvent)}
            </Button>
          </AlertCentreCardTitle>
          <div className="alertCentre-content-1">
            <AlertCentreMessage>{record?.message}</AlertCentreMessage>
          </div>
        </div>
        <AlertTableRow>
          <Col className="alertCentre-col-1" >
            {/* <div>
          <AlertCentreCardTitle>
            {getAlertIcon(data?.severity)}
            <p>{data?.alertName}</p>
          </AlertCentreCardTitle> */}
            {/* <div className="alertCentre-content-1">
            <AlertCentreMessage>{data?.message}</AlertCentreMessage> */}
            <div className="alertCentre-content-1">
              <Row>
                <AlertCentreKey>{t(jsonData.Location)}</AlertCentreKey>
                <AlertCentreValue>{record?.hierarchyName}</AlertCentreValue>
              </Row>
              <Row>
                <AlertCentreKey>{t(jsonData.Device)}</AlertCentreKey>
                <AlertCentreValue>{record?.srcName}</AlertCentreValue>
              </Row>
              <Row>
                <AlertCentreKey>{t(jsonData.Severity)}</AlertCentreKey>
                <AlertCentreValue className="alertCentre-severity">{record?.severity}</AlertCentreValue>
              </Row>
              <Row>
                <AlertCentreKey>{t(jsonData.DisplayText)}</AlertCentreKey>
                <AlertCentreValue className="alertCentre-severity">{record?.DisplayText}</AlertCentreValue>
              </Row>

              {record?.isCleared && (record?.clearingMethod?.toString().toLowerCase() === ClearAlertMethod.MANUAL.toLowerCase()) ?
                <Row>
                  <AlertCentreKey>{jsonData.ClearedBy}</AlertCentreKey>
                  <AlertCentreValue>{record?.updatedBy}</AlertCentreValue>
                </Row>
                : ''
              }
            </div>
            {/* </div> */}
            {/* </div> */}
          </Col>
          <Col className="alertCentre-col-1" >
            <div className="alertCentre-content-2">
              <Row>
                <AlertCentreKey>{t(jsonData.Occurred)}</AlertCentreKey>
                <AlertCentreValue>{getOrganizationDateAndTime(record?.eventTimeStamp, localeInfo.momentFormat + " " + DevicePickerFormat.TIME)}</AlertCentreValue>
              </Row>
              <Row>
                <AlertCentreKey>{t(jsonData.AlertCondition)}</AlertCentreKey>
                <AlertCentreValue>{record?.isCleared ? t(jsonData.Cleared) : t(jsonData.Active)}</AlertCentreValue>
              </Row>
              <Row>
                <AlertCentreKey>{t(jsonData.EventId)}</AlertCentreKey>
                <AlertCentreValue>{record?.alertTypeId}</AlertCentreValue>
              </Row>
              {record?.partName ?
                <Row>
                  <AlertCentreKey>{t(jsonData.PartName)}</AlertCentreKey>
                  <AlertCentreValue>{record?.partName}</AlertCentreValue>
                </Row>
                : ''
              }
              {record?.isCleared && (record?.clearingMethod?.toString().toLowerCase() === ClearAlertMethod.MANUAL.toLowerCase()) ?
                <Row>
                  <AlertCentreKey>{t(jsonData.Reason)}</AlertCentreKey>
                  <AlertCentreValue>{record?.reason}</AlertCentreValue>
                </Row>
                : ''
              }
            </div>
          </Col>
          <Col className="alertCentre-col-1" >
            <div className="alertCentre-content-2">
              {
                !record?.isCleared ?
                  <AlertClearForm onFinish={onFinish} disable={disable} rowData={record} form={form} />
                  :
                  <>
                    <Row>
                      <AlertCentreKey>{t(jsonData.ResolvedAt)}</AlertCentreKey>
                      <AlertCentreValue>{getOrganizationDateAndTime(record?.updatedAt, localeInfo.momentFormat + " " + DevicePickerFormat.TIME)}</AlertCentreValue>
                    </Row>
                    <Row>
                      <AlertCentreKey>{t(jsonData.ElapsedTime)}</AlertCentreKey>
                      <AlertCentreValue>{getElapsedTime(record?.eventTimeStamp, { elapsedFrom: record?.updatedAt })}</AlertCentreValue>
                    </Row>
                    <Row>
                      <AlertCentreKey>{t(jsonData.Method)}</AlertCentreKey>
                      <AlertCentreValue>{record?.clearingMethod?.toString().toLowerCase() === ClearAlertMethod.MANUAL.toLowerCase() ? ClearAlertMethod.MANUAL : ClearAlertMethod.AUTOMATIC}</AlertCentreValue>
                    </Row>
                  </>
              }
            </div>
          </Col>
        </AlertTableRow>
      </div>
  };

  const setRowClassName = (record: any) => {
    return record.id === rowId ? "alertCentre-expanded" : "";
  }

  const onParamsUpdated = (params: any) => {
    setLoading(true)
    setApiCalled(true)
    setToInitial()

    if (!params?.page) {
      setCurrentPage(DEFAULT_PAGE)
    }

    let parameters: any = {
      ...apiParams,
      ...params,
      limit: ALERT_DEFAULT_LIMIT,
      page: params?.page ?? DEFAULT_PAGE
    }

    if (!filtersChanged && !alertClicked) {
      if (filteredInfo?.severityText) {
        parameters.severity = filteredInfo?.severityText
      }
      if (filteredInfo?.alertName) {
        parameters.alertName = filteredInfo?.alertName
      }
      if (filteredInfo?.hierarchyName) {
        parameters.hierarchyIdList = filteredInfo?.hierarchyName
      }
      if (filteredInfo?.srcName) {
        parameters.deviceIdList = filteredInfo?.srcName
      }
      if (filteredInfo?.partName) {
        parameters.partName = filteredInfo?.partName
      }
    }

    if (alertClicked && params?.severity) {
      parameters = {}
      parameters.organizationId = params?.organizationId
      parameters.severity = params?.severity
      const severityFilter = params?.severity
      setFilteredInfo({ severityText: severityFilter })
    }

    if (additionalApiPayload) {
      parameters.deviceName = additionalApiPayload?.deviceName;
      parameters.partId = additionalApiPayload?.partId;
      parameters.partInstanceId = additionalApiPayload?.partInstanceId;
      parameters.isComponentAlert = additionalApiPayload?.isComponentAlert;
    } else {
      parameters.deviceName = undefined;
      parameters.partId = undefined;
      parameters.partInstanceId = undefined;
      parameters.isComponentAlert = undefined;
    }
    
    setApiParams(parameters)
    dispatch(fetchAlertByOrgId(parameters))
    setPageChanged(false)
    dispatch(setAdditionalApiPayload(null))
  }

  const onPageChange = (page: any) => {
    setPageChanged(true)
    setApiParams({ ...apiParams, page: page, limit: ALERT_DEFAULT_LIMIT })
    setCurrentPage(page)
  }

  useEffect(() => {
    if (pageChanged) {
      onParamsUpdated(apiParams)
    }
  }, [apiParams])


  const setToInitial = () => {
    const node = document.querySelector<HTMLElement>(
      ".table .ant-table-body"
    );
    node?.scrollTo(0, 0);
    // setTotalCleared(0)
    // setTotalRows(0)
    setCurrentIndex(0)
    setTableData([])
    setUnfilteredData([])
    setSelectedRowKeys(null)
    setRowId(null)
  }

  const onScroll = (event: any) => {
    if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      setScrolledToEnd(true);
    }
  }

  const fetchData = async (startIndex: number) => {
    const data = unfilteredData.slice(startIndex, startIndex + ALERT_PAGE_SIZE);
    return data;
  }

  const handleFetch = () => {
    if (tableData?.length !== unfilteredData?.length && scrolledToEnd) {
      setScrolledToEnd(false);
      fetchData(currentIndex).then(async (newData: any) => {
        setTableData(tableData.concat(newData));
        setCurrentIndex(currentIndex + ALERT_PAGE_SIZE);
      }
      );
    }
  }

  useEffect(() => {
    if (filtersChanged) {
      onParamsUpdated({
        ...apiParams,
        page: DEFAULT_PAGE,
        severity: filteredInfo?.severityText,
        alertName: filteredInfo?.alertName,
        hierarchyIdList: filteredInfo?.hierarchyName,
        deviceIdList: filteredInfo?.srcName,
        partName: filteredInfo?.partName
      })
      setFiltersChanged(false)
    }
  }, [filteredInfo])

  useEffect(() => {
    setTableColumns(
      alertCentreTableColumn(
        severityFilter,
        filteredInfo,
        alertFilter,
        locationFilter,
        deviceFilter,
        partNameFilter
      )
    )
  }, [severityFilter, filteredInfo, alertFilter, locationFilter, deviceFilter,alertType,partNameFilter])

  let element: any = document.querySelectorAll('.ant-table-row');
  element.forEach((element) => {
  
  if(element){
    element?.setAttribute("tabIndex", "0");
   
  }});

  return (
    <AlertCentrediv >
      <div className="pl-vw">
        {/* doubt */}
        {/* <Row
          */}
        {/* > */}
          <AlertHeader
            onParamsUpdated={onParamsUpdated}
            alertType={alertType}
            setAlertType={setAlertType}

          />
        {/* </Row> */}
      </div>
      {/* <Loader loading={fetchAlertFormState?.loading || loading}> */}
        <div className="alertCentreDiv">
          <AntdInfinityTable
            tableClassName="alertTableDiv"
            expandable={expandable}
            pagination={false}
            onScroll={onScroll}
            scroll={{
              y: scrollHeight,
              scrollToFirstRowOnChange: true,
            }}
            //@ts-ignore
            dataSource={tableData}
            className={`table alertCentre`}
            //@ts-ignore
            columns={tableColumns}
            expandedRowKeys={[selectedRowKeys]}
            onRow={onRow}
            rowClassName={setRowClassName}
            locale={!fetchAlertFormState?.loading && !unfilteredData?.length && !tableData.length && { emptyText: t(jsonData.NoDataFound) }}
            rowKey="id"
            // loading={loading}
            onFetch={handleFetch}
            pageSize={ALERT_DEFAULT_LIMIT}
          />
          {
            (totalAlerts / ALERT_DEFAULT_LIMIT) > 1 &&
            <AntdPagination className='alert-pagination' total={totalAlerts} current={currentPage} defaultPageSize={ALERT_DEFAULT_LIMIT} onChange={(page: any, pageSize: any) => onPageChange(page)} />
          }
        </div>
      {/* </Loader> */}

      {/* Anubhav - commented Loader and added this */}
      {fetchAlertFormState?.loading || loading ? <Loader loading={true} /> : null} 
    </AlertCentrediv>
  );
};

export default AlertCentre;